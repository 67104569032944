import { post } from '@/utils/request'

//获取目录
export function getList(data) {
    return post('psi/service/listService', data)
}
//修改
export function getEdit(data) {
    return post('psi/service/editService', data)
}
//添加
export function getAdd(data) {
    return post('psi/service/addService', data)
}
//删除
export function getDel(data) {
    return post('psi/service/delService', data)
}
//分类下拉
export function getOption(data) {
    return post('psi/classify/downward', data)
}
//商品id
export function getOption2(data) {
    return post('psi/commodity/xiala', data)
}
// 供应商id
export function getOption3(data) {
    return post('psi/vendor/xiala', data)
}
//详情
export function getInfo(data) {
    return post('psi/service/infoService', data)
}
//修改状态
export function getState(data) {
    return post('psi/service/servicePic', data)
}






